import React from 'react';
import { Helmet } from 'react-helmet';
import UserProfile from './user-profile';

const VideoSharing = ({ platform, videoData }) => {
    console.log(`platform: ${videoData.platform}`);
    const appStoreLink = videoData.platform === 'ios' ? 'itms-apps://apps.apple.com/ae/app/ginni/id6504367924' : "market://details?id=com.zinni";
    const pageTitle = videoData.mediaType === '2' ? 'Mini' : 'Post';

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',  // Align content to the top
            alignItems: 'center',  // Center content horizontally
            height: '100vh',  // Take full viewport height
            overflow: 'hidden',  // Prevent any overflow
            padding: '10px',
            boxSizing: 'border-box'
        }}>
            <Helmet>
                <title>{videoData.title}</title>
                <meta name="description" content={`${pageTitle} by ${videoData.userName}`} />
                <meta name="keywords" content={videoData.keywords} />
                <meta property="og:title" content={videoData.title} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={`https://share.ginni.app/${videoData.shareCode}`} />
                <meta property="og:description" content={`${pageTitle} by ${videoData.userName}`} />
                <meta property="og:image" content={`https://ik.imagekit.io/2msamvwg9/${videoData.mediaThumbnailFile}`} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:image:secure_url" content={`https://ik.imagekit.io/2msamvwg9/${videoData.mediaThumbnailFile}`} />
                <meta property="og:site_name" content="Ginni Social" />
                <meta property="og:locale" content="en_US" />
            </Helmet>

            <div className="post-container" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',  // Center content horizontally
                width: '100%',
                maxWidth: '600px',
                textAlign: 'center',
                overflow: 'hidden'
            }}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start', // Aligns the content to the left
                    alignItems: 'center',  // Centers content vertically                    
                }}>
                    <UserProfile
                        userName={videoData.userFullName}
                        userImage={`https://ik.imagekit.io/2msamvwg9/${(videoData.userProfilePic || '')}?tr=w-100`}
                    />
                </div>

                <div className="media-container" style={{
                    width: '100%',
                    maxHeight: '60vh',
                    overflow: 'hidden',
                }}>
                    {videoData.mediaType === '2' ? (
                        <video
                            playsInline
                            controls
                            poster={`https://ik.imagekit.io/2msamvwg9/${(videoData.mediaThumbnailFile || '')}?tr=w-700`}
                            style={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '60vh',  // Constrain the video height
                                borderRadius: '10px',
                                objectFit: 'contain'
                            }}>
                            <source src={`https://content-creator-reel-star-bucket.s3.ap-south-1.amazonaws.com/${videoData.mediaFile}`} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        // If mediaType is 1
                        <div className="media-thumbnail" style={{ position: 'relative', marginBottom: '15px' }}>
                            <img
                                src={`https://ik.imagekit.io/2msamvwg9/${(videoData.mediaThumbnailFile || '')}?tr=w-700`}
                                alt="Media Thumbnail"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxHeight: '60vh',  // Constrain the video height
                                    borderRadius: '10px',
                                }}
                            />

                            <div className="actions" style={{
                                position: 'absolute',
                                right: '15px',
                                bottom: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                                <button className="action-icon-container" style={{ background: 'transparent', border: 'none', marginBottom: '15px' }}>
                                    <img src="/assets/images/bookmark.png" alt="Bookmark" style={{ width: '24px', height: '24px', color: "white" }} />
                                </button>
                                <button className="action-icon-container" style={{ background: 'transparent', border: 'none', marginBottom: '15px' }}>
                                    <img src="/assets/images/send.png" alt="Send" style={{ width: '24px', height: '24px', color: "white" }} />
                                </button>
                                <div className="action-icon-container" style={{ backgroundColor: 'transparent', borderRadius: '15px', padding: '5px 10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src="/assets/images/chat.png" alt="Comment" style={{ width: '24px', height: '24px', color: "white" }} />
                                    <span className="action-icon-text" style={{ color: 'white', fontSize: '12px', marginTop: '5px', }}>{videoData.totalComments || 0}</span>
                                </div>
                                <div className="action-icon-container" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '15px', padding: '5px 10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src="/assets/images/heart.png" alt="Heart" style={{ width: '24px', height: '24px', color: "white" }} />
                                    <span className="action-icon-text" style={{ color: 'white', fontSize: '12px', marginTop: '5px', }}>{videoData.totalLikes || 0}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="user-info" style={{ marginTop: '10px' }}>
                    <p>{videoData.title}</p>
                </div>

                <div className="download-app" style={{ textAlign: 'center', marginTop: '5px' }}>
                    <button
                        onClick={() => window.open(appStoreLink, '_blank')}
                        style={{
                            backgroundColor: 'purple',
                            color: 'white',
                            padding: '15px 20px',
                            borderRadius: '12px',
                            border: 'none',
                            cursor: 'pointer',
                            width: '100%',
                            maxWidth: '365px'
                        }}
                    >
                        Download App
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VideoSharing;
